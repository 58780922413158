import React from 'react'
import tw, { styled } from 'twin.macro'


const CoverWrapper = styled.div`
  background-image: url(${props => props.coverImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
  ${tw`w-full bg-no-repeat bg-center bg-cover`}
`

const CoverSection = styled.div`
  ${tw`max-w-screen-xl mx-auto px-5 sm:px-10 medium:px-0`}
`
const CoverContentContainer = tw.section`py-36 md:pt-19 md:pb-81/2`;

const PageCoverTitle = tw.h1`text-9/2 mb-4 lg:text-brand-large tracking-brand text-white font-mulish font-extrabold leading-tight lg:mb-0`

const PageBreadCrumb = styled.div`
  ${tw`flex text-white font-lato font-normal tracking-brand items-center`}
  font-size: 16px;
  line-height: 1.23;
  margin-top: 15px;

  > * {
    padding-right: 5px;
  }
`
  

const PageCover = props => {
  return (
    <CoverWrapper coverImage={props.imageSrc}>
      <CoverSection>
        <CoverContentContainer>
          <PageCoverTitle>{props.coverTitle}</PageCoverTitle>
          <PageBreadCrumb>
            {props.children}
          </PageBreadCrumb>
        </CoverContentContainer>
      </CoverSection>
    </CoverWrapper>
  )
}

export default PageCover